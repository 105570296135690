$header-height: 76px;
$footer-height: 48px;

// Color system

$white:    #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-450: #e4e4e4 !default;
$gray-550: #e1e1e1 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$lightGray: #b1b1b1;
$black:    #333333;
$lightBlack: #666666;
$dark:    #000000;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #cd2151;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #bfbf0f;
$cyan:    #17a2b8 !default;
$sea-blue: #8AB4F8;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;

$disabled: #d4d4d4;
$placeholder: #999999;
$gray-bg: #f6f6f6;
$gray-track: #f5f5f5;
$gray-track-hover: #e1e1e1;
$gray-dark-bg: #e5e5e5;
$gray-light-bg: #f4f4f4;
$gray-border: #e4e4e4;
$dark-border: #2d2d2d;
$checkbox-disabled: #d9d9ca;
$shadow-gray: #e9e9e9;
$pink-gray: #fafafa;
$yellow-gray: #fcfced;
$disabled-value: #b0b0b0;
$button-gray: #5b5c60;
$button-gray-selected: #8c8f98;
$airbnb: #FF5A5F;

$box-gradiend: linear-gradient(to bottom, rgba($dark, 0.1) 0%, rgba($dark, 0) 11%, rgba($dark, 0) 59%, rgba($dark, 0.4) 100%);

$breakpoint-xxl: 1450px;
$breakpoint-xl: 1279px;
$breakpoint-lg: 1024px;
$min-breakpoint-lg: 1025px;
$breakpoint-md: 767px;
$breakpoint-sm: 575px;
$breakpoint-xs: 400px;
